footer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 3em;
  background-color: var(--champ-pink);
}

footer > * {
  position: relative;
  z-index: 3;
  color: white;
}

.footer-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.footer-bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.2;
}

.footer-bg::after {
  content: "";
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  /* background-color:#893935cb; */
}

footer .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1em;
}

.tagline {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.tagline p:first-of-type {
  font-size: 1.8em;
  font-weight: 500;
}

.footer-buttons {
  display: flex;
  gap: 1em;
  margin-top: 1em;
}

.footer-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4em;
  width: 100%;
}

.footer-links {
  display: flex;
  gap: 0.5em;
  width: 60%;
  flex-wrap: wrap;
}

.footer-link > * {
  text-transform: capitalize;
  margin-right: 1em;
  text-decoration: none;
}

.footer-link a:hover {
  color: var(--umber);
}

.footer-icons {
  display: flex;
  gap: 1em;
}

.bottom-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  width: 100%;
  margin-top: 3em;
  padding: 2em 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--umber);
}

.bottom-footer > * {
  color: white;
}

.footer-widget {
  display: flex;
  gap: 1em;
  padding-left: 5%;
}

.footer-widget > * {
  color: white;
}

.copyright {
  margin-right: 5%;
}

.footer-widget a {
  text-decoration: none;
}

@media (max-width: 700px) {
  .bottom-footer {
    flex-direction: column;
    gap: 1em;
  }

  .footer-widget {
    padding-left: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .footer-menu {
    flex-direction: column;
    gap: 2em;
  }

  .footer-links {
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .copyright {
    margin: 0;
  }
}
