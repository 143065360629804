.testimonial-slide__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 4em 0;
}

.testimonial-slide__section h2 {
  text-align: center;
}

.testimonial-slide__section p:first-of-type {
  color: var(--umber);
}

.testimonial-slide__section .container {
  margin-top: 2em;
}

.testimonial-slide__section .container .mySwiper {
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.testimonial-slide__single {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 4em 0;
}

.testimonial-slide__section .mySwiper .swiper-wrapper .swiper-slide {
  width: 1109px;
}

.slide-inner {
  height: 100%;
  width: 67%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4em;
  position: relative;
}

.rating {
  display: flex;
  gap: 0.5em;
  position: absolute;
  top: -0.6em;
}

#author__name {
  color: var(--umber);
  text-align: center;
  font-size: 1.7em;
}

.q1 {
  position: absolute;
  left: 5%;
  top: 5%;
}

.q2 {
  position: absolute;
  right: 5%;
  bottom: 5%;
}

.quote p {
  font-size: 5em;
  color: rgba(0, 0, 0, 0.05) !important;
}

#author__message {
  margin-top: 2.5em;
  text-align: center;
  font-size: 1.5em;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 1.5em !important;
  /* background-color: var(--umber); */
  color: var(--umber);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
}

@media (max-width: 768px) {
  #author__message {
    font-size: 1.1em;
  }

  .quote p {
    font-size: 3em;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: 2em;
  }
}

@media (max-width: 480px) {
  #author__message {
    font-size: 1em;
  }

  #author__name {
    font-size: 1.4em;
    width: 100%;
  }

  .slide-inner {
    width: 100%;
    padding: 1.5em;
  }
}
