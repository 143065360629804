.ngungwulah__section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ngungwulah__section .container {
    padding-top: 8em;
    text-align: center;
}

.ngungwulah--row {
    display: flex;
    justify-content: space-between;
    margin: 8em 0;
}

.ngungwulah--image {
    width: 48%;
}

.ngungwulah--image img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.ngungwulah--text {
    width: 48%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: flex-start;
    text-align: left;
}

@media (max-width:1057px) {
    .ngungwulah--text h2 {
        font-size: 2.5em;
    }
}

@media (max-width:992px) {
    .ngungwulah--row {
        flex-direction: column;
        margin-top: 6em;
        margin-bottom: 4em;
    }

    .ngungwulah--image,
    .ngungwulah--text {
        width: 100%;
    }

    .ngungwulah--text {
        margin-top: 2em;
    }
}