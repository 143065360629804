/* .testimonialslider__section {
    width: 100%;
    padding: 5% 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
*/
.swiper-section {
    padding: 8em 0;
    margin-left: 0.5em;
}

.testimonial__swiperslide {
    /* width: 398px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2em 1em;
    height: 20em;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.103);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.testimonial__swiperslide .review__rating {
    display: flex;
    gap: 0.5em;
    position: absolute;
    top: 0;
}

.left-quote>* {
    font-size: 4em;
    opacity: 0.1;
}

.testimonial__swiperslide p {
    text-align: center;
    margin-top: 2em;
}

.testimonial__swiperslide h3 {
    margin-top: 2em;
}

/* ============== */