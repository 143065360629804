.newsletter {
  width: 100%;
  padding: 4em 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light-bg);
}

.newsletter .form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  text-align: center;
  gap: 1em;
}

.news-letter__form {
  margin-top: 1em;
}

.news-letter__form input {
  font-size: 1.2em;
  padding: 0.5em;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: none;
}

.news-letter__form input[type="email"]:focus {
  border: 1px solid var(--umber);
}

.news-letter__form input[type="submit"] {
  border: 1px solid var(--umber);
  cursor: pointer;
  transition: all ease 500ms;
  color: black;
}

.news-letter__form input[type="submit"]:hover {
  background-color: var(--umber);
  color: white;
}

@media (max-width: 647px) {
  .newsletter .form-container {
    width: 90%;
  }

  .news-letter__form input {
    font-size: 0.8em;
  }
}
.success-message {
  margin-top: 1em;
  color: green;
  font-weight: bold;
}
