.wayapa__section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.wayapa__section .container {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.wayapa__section .container h3 {
  margin-bottom: 2em;
}

.wayapa-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wayapa__image {
  width: 48%;
  text-align: left;
}
.wayapa__text {
  width: 100%;
  text-align: left;
}

.wayapa__text p {
  margin-bottom: 1em;
}

.wayapa__image img {
  height: auto;
  width: 100%;
  object-fit: cover;
}

.wayapa__grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  gap: 1.5em 1.5em;
  margin-top: 6em;
}

.wayapa__grid .wayapa__image {
  width: 100%;
  overflow: hidden;
}

.wayapa__grid .wayapa__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all ease 500ms;
}

.wayapa__grid .wayapa__image:hover img {
  transform: scale(1.1);
}

@media (max-width: 1000px) {
  .wayapa-row {
    flex-direction: column-reverse;
  }

  .wayapa__text,
  .wayapa__image {
    width: 100%;
  }

  .wayapa__text {
    margin-top: 2em;
  }
}

@media (max-width: 992px) {
  .wayapa__grid {
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  }

  .wayapa__grid {
    margin-top: 4em;
  }
}

@media (max-width: 648px) {
  .wayapa__grid {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
  }
}
