.videos-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 4em;
}

.videos-section .container {
  display: grid;
  grid-template-columns: repeat(3, minmax(250px, 1fr));
  gap: 1em 1em;
  margin-top: 2em;
}

.video-container {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.podcast-card {
  height: 350px;
  width: 100%;
  padding: 0.5em;
  background-color: #1e1e1e;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
}

.podcast-image {
  height: 65%;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 0.5em;
}

.podcast-image::after {
  content: " ";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  transition: all ease 500ms;
  background: linear-gradient(#8339353c, #833935d2);
}

.podcast-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 0.5em;
  transition: all ease 0.5s;
}

.podcast-details {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  gap: 0.5em;
  position: relative;
}

.podcast-details p:not(h3 p) {
  color: white;
  background-color: gray;
  border-radius: 0.5em;
  width: fit-content;
  font-size: 0.9em;
  padding: 0 0.5em;
}

.podcast-details h3 {
  font-size: 1.1em;
  color: white;
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.podcast-details h3 a {
  color: white;
  color: greenyellow;
  transition: all ease 0.5s;
  transition-delay: 0.5s;
  font-size: 1.5em;
}

#link-to-podcast {
  transform: translateY(15px);
  transition: all ease 0.5s;
  opacity: 0;
}

.podcast-card:hover #link-to-podcast {
  transform: translateY(0);
  opacity: 1;
}

.podcast-card:hover #link-to-podcast a {
  transform: rotate(-30deg);
}

.podcast-card:hover img {
  transform: scale(1.05);
  border-radius: 0.5em;
}

.podcast-card:hover .podcast-image::after {
  display: none;
}

@media (max-width: 868px) {
  .videos-section .container {
    grid-template-columns: repeat(2, minmax(250px, 1fr));
  }
}

@media (max-width: 570px) {
  .videos-section .container {
    grid-template-columns: repeat(1, minmax(250px, 1fr));
  }
}
