.consultancy__screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3em;
  justify-content: center;
  width: 100%;
}
.home-content {
  padding-bottom: 1em;
}
.consultancy__screen .container .consultancy-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.consultancy--text,
.consultancy--image {
  width: 49%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.consultancy--image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.consultancy--text h2 {
  margin-bottom: 0.5em;
}

.consultancy--text p {
  margin-bottom: 1em;
}
.consultancy--text span {
  font-weight: 700;
  line-height: 20px;
}
.consultancy--text em {
  font-size: 17px;
}

.consultancy-row.reverse {
  flex-direction: row-reverse;
  margin-top: 6em;
}

.consultancy-banner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0em;
  /* paddin; */
}

.consultancy-banner__points {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1em;
  width: 80%;
}

.consultancy-banner__points ul p {
  font-size: 1.3em;
  font-weight: bold;
}

.consultancy-banner__points ul {
  list-style-type: none;
}

.consultancy-banner__points ul li {
  font-size: 1.18em;
}

@media (max-width: 940px) {
  .consultancy__screen .container .consultancy-row {
    flex-direction: column;
  }

  .consultancy--text,
  .consultancy--image {
    width: 100%;
  }

  .consultancy__screen {
    padding-top: 1em;
  }
}
.consultant__text,
.consultant__image {
  width: 49% !important;
  text-align: left !important;
}

.consultant__text p {
  margin-bottom: 1em !important;
}

.consultant__image img {
  height: auto !important;
  width: 100% !important;
  object-fit: cover !important;
}

.consultant__grid {
  display: grid !important;
  grid-template-columns: repeat(3, minmax(300px, 1fr)) !important;
  gap: 1.5em 1.5em !important;
  margin-top: 6em !important;
}

.consultant__grid .consultant__image {
  width: 100% !important;
  overflow: hidden !important;
}

.consultant__grid .consultant__image img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  transition: all ease 500ms !important;
}

.consultant__grid .consultant__image:hover img {
  transform: scale(1.1) !important;
}

@media (max-width: 1000px) {
  .consultant-row {
    flex-direction: column-reverse !important;
  }

  .consultant__text,
  .consultant__image {
    width: 100% !important;
  }

  .consultant__text {
    margin-top: 2em !important;
  }
}

@media (max-width: 992px) {
  .consultant__grid {
    grid-template-columns: repeat(2, minmax(300px, 1fr)) !important;
  }

  .consultant__grid {
    margin-top: 4em !important;
  }
}

@media (max-width: 648px) {
  .consultant__grid {
    grid-template-columns: repeat(2, minmax(150px, 1fr)) !important;
  }
}
/* .new-para-text {
  margin-bottom: 0px !important;
} */
