.weali__section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.weali__section .container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.text__section {
  margin-bottom: 1em;
}

.weali__text h2,
.weali__text h3 {
  margin-bottom: 0em;
}

.weali__text p {
  margin-bottom: 0em;
}

.weali__images {
  width: 91%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 6em;
  flex: auto;
  gap: 10px;
}

.weali__image {
  width: 25%;
  height: 25em;
}

.weali__image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.wayapaimage {
  width: 90%;
  height: 500px;
  padding: 0 0 50px 0;
}
.wayapaimage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 992px) {
  .weali__image {
    height: 20em;
  }
}

@media (max-width: 720px) {
  .weali__image {
    height: 15em;
  }
}

@media (max-width: 575px) {
  .weali__images {
    flex-direction: column;
    gap: 1em;
  }

  .weali__image {
    width: 100%;
    height: 18em;
  }
}
