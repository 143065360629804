.ipat__section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ipat__section .container {
  display: flex;
  flex-direction: column;
  margin: 8em 0 4em 0;
  text-align: center;
}

.ipat__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6em;
}

.ipat__row-text,
.ipat__row-image {
  width: 49%;
  display: flex;
  text-align: left;
  flex-direction: column;
  gap: 1em;
  margin-top: -207px;
}

.ipat__row-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.ipat__banner {
  margin-top: 4em;
  padding: 6em 0;
  width: 100%;
  background-color: var(--light-bg);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.ipat__banner h2 {
  text-align: center;
}

.ipat__banner--grid {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(4, minmax(250px, 1fr));
  gap: 1.5em;
  margin-top: 4em;
}

.ipat__grid-col {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  text-align: center;
}

.ipat__grid-col img {
  height: 6em;
  width: 6em;
}

.ipat__grid-col p:first-of-type {
  font-size: 2em;
}

.ipat__row.reverse {
  flex-direction: row-reverse;
  margin-top: 0;
}

@media (max-width: 1154px) {
  .ipat__banner--grid {
    grid-template-columns: repeat(2, minmax(250px, 1fr));
    gap: 3em;
  }
}

@media (max-width: 1040px) {
  .ipat__section .container {
    margin-top: 6em;
  }

  .ipat__row.reverse {
    flex-direction: column;
  }

  .ipat__row.reverse .ipat__row-text,
  .ipat__row.reverse .ipat__row-image {
    width: 100%;
  }

  .ipat__row-text {
    margin-bottom: 2em;
  }
}

@media (max-width: 840px) {
  .ipat__row {
    flex-direction: column;
    gap: 2em;
  }

  .ipat__row-text {
    width: 100%;
    margin-top: -120px;
  }

  .ipat__row-image {
    margin-top: 0px;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .ipat__banner--grid {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }
}

@media (max-width: 490px) {
  .ipat__banner--grid {
    grid-template-columns: repeat(1, minmax(150px, 1fr));
  }
}

.ipat-link {
  text-decoration: none;
  font-size: large;
  color: var(--umber);
}

.ipat-link:hover {
  text-decoration: underline;
}
