.about__section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.about__section .container {
  padding: 5em 0;
}

.about__row {
  display: flex;
  justify-content: space-between;
}

.about__image,
.about__text {
  width: 48%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.about__text p,
.about__text--bottom p {
  line-height: 1.3;
}

.about__text--bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  text-align: center;
  margin-top: 4em;
}

.about__image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.clients {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2em 2em;
  flex-wrap: wrap;
  margin-top: 8em;
}

.clients .client {
  width: 20%;
}

.clients .client img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.about__grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(300px, 1fr));
  gap: 1.5em 1.5em;
  margin-top: 6em;
}

.about__grid .about__image {
  width: 100%;
  overflow: hidden;
}

.about__grid .about__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all ease 500ms;
}

.about__grid .about__image:hover img {
  transform: scale(1.1);
}

@media (max-width: 1040px) {
  .about__image img {
    height: auto;
    object-fit: cover;
  }

  .about__image,
  .about__text {
    width: 48%;
  }

  .about__text {
    gap: 1em;
  }

  .about__text h2 {
    font-size: 2.5em;
  }

  .about__text p,
  .about__text--bottom p {
    line-height: normal;
  }

  .about__text--bottom {
    text-align: left;
    margin-top: 2em;
    gap: 1em;
  }
}

@media (max-width: 870px) {
  .about__row {
    flex-direction: column;
  }

  .about__image,
  .about__text {
    width: 100%;
  }

  .about__text {
    margin-top: 2em;
  }
}
