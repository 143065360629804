.breadcrumb {
    width: 100%;
    height: 25em;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.breadcrumb-bg>* {
    position: relative;
    z-index: 3;
}

.breadcrumb-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.breadcrumb-bg img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.breadcrumb-bg::after {
    content: " ";
    background: linear-gradient(to right, rgba(0, 0, 0, 0.605), rgba(0, 0, 0, 0.48));
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
}

.breadcrumb h2 {
    color: white;
}

.breadcrumb .container {
    height: 100%;
}

.title__section {
    position: relative;
    z-index: 4;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
}

.breadcrumb__links span {
    display: flex;
    align-items: center;
    gap: 0.2em;
}

.breadcrumb__links span a:hover {
    text-decoration: underline;
}

.breadcrumb__links span>* {
    text-decoration: none;
    color: white;
    font-size: 1.2em;
}