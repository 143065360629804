.practitioner__section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.practitioner__section .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.practitioner__section .container h3,
.practitioner__text h2,
.practitioner__text p {
    margin-bottom: 0.5em;
}

.practitioner--row {
    display: flex;
    justify-content: space-between;
    margin-top: 6em;
}

.practitioner__text,
.practitioner__image {
    width: 49%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
}

.practitioner__image img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.practitioner__image img:nth-of-type(2) {
    display: none;
}

.practitioner__banner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: var(--light-bg);
    padding: 4em 0;
}

.practitioner__banner .container {
    padding: 4em 0;
}

.practitioner__grid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, minmax(250px, 1fr));
    gap: 2em;
}

.practitioner__grid .col {
    width: 100%;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}

.practitioner__grid .col ul {
    list-style-type: none;
}

.practitioner__grid .col ul li {
    margin-bottom: 1em;
}

.practitioner__grid .col ul li p {
    display: flex;
    align-items: center;
    gap: 0.5em;
}

.practitioner__grid .col ul li p {
    display: flex;
    align-items: center;
    gap: 0.5em;
}

.practitioner__grid .col ul li p i {
    color: var(--umber);
}

@media (max-width:908px) {
    .practitioner__image img:nth-of-type(2) {
        display: block;
        margin-top: 2em;
    }
}

@media (max-width: 875px) {
    .practitioner__grid {
        grid-template-columns: repeat(2, minmax(250px, 1fr));
    }
}

@media (max-width:826px) {
    .practitioner--row {
        flex-direction: column-reverse;
    }

    .practitioner__text,
    .practitioner__image {
        width: 100%;
    }

    .practitioner__text {
        margin-top: 2em;
    }

    .practitioner__image img:nth-of-type(2) {
        display: none;
    }
}

@media (max-width: 565px) {
    .practitioner__grid {
        grid-template-columns: repeat(1, minmax(250px, 1fr));
    }
}