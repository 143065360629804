.text-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
}

.text-content .container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
  text-align: center;
  padding: 8em 0;
  position: relative;
}

.text-content h2 {
  margin-bottom: 0.5em;
  line-height: 1;
}

.watermark {
  position: absolute;
  top: 1em;
}

.watermark img:first-of-type {
  width: 7em;
  height: 7em;
  object-fit: cover;
  opacity: 0.9;
}

/* .image-new {
  width: 90%;
} */
