.corporate__section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.corporate__section .container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-top: 8em;
    gap: 1em;
}

.corporate__upcoming-events {
    display: flex;
    justify-content: space-between;
    margin-top: 8em;
}

.corporate__event--text,
.corporate__event--image {
    width: 48%;
}

.corporate__event--image img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.corporate__event--text {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 1em;
}

.corporate__event--text ul {
    margin-left: 2em;
}

@media (max-width:1042px) {
    .childrenwork__text--grid {
        flex-direction: column;
        gap: 3em;
        align-items: center;
        justify-content: center;
    }

    .text-col {
        width: 80%;
    }

    .childrenwork__row {
        flex-direction: column-reverse;
        gap: 2em;
    }

    .childrenwork__row .col:last-of-type,
    .childrenwork__row .col:first-of-type {
        width: 100%;
    }

    .corporate__upcoming-events {
        margin-top: 6em;
    }
}

@media (max-width:960px) {
    .corporate__event--image {
        width: 60%;
    }

    .corporate__event--text {
        width: 38%;
    }
}

@media (max-width:840px) {
    .corporate__upcoming-events {
        flex-direction: column;
        gap: 2em;
    }

    .corporate__event--text,
    .corporate__event--image {
        width: 100%;
    }
}