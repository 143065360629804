.workshop__section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 8em;
}


.workshop__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.workshop__row.reverse {
    margin: 6em 0;
    display: flex;
    flex-direction: row-reverse;
}

.workshop__image {
    width: 55%;
}

.workshop__text {
    width: 42%;
}

.workshop__image img {
    width: 100%;
    height: auto;
}

.workshop__text {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

@media (max-width:930px) {

    .workshop__row,
    .workshop__row.reverse {
        flex-direction: column;
    }

    .workshop__image,
    .workshop__text {
        width: 100%;
    }


}