.childrenwork__section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.childrenwork__section .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.childrenwork__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.childrenwork__text h2 {
    margin-bottom: 0.5em;
}

.childrenwork__text p {
    margin-bottom: 1em;
}

.childrenwork__text--grid {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 6em;
}

.text-col {
    width: 30%;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 1em;
}

.childrenwork__row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 6em;
}

.childrenwork__row .col:first-of-type {
    width: 35%;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
}

.childrenwork__row .col:last-of-type {
    width: 60%;
}

.childrenwork__row .col:last-of-type img {
    width: 100%;
    height: 30em;
    object-fit: cover;
}