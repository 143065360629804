header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding-bottom: 0.5em;
  background-color: white;
}

header .container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 1em;
}
header .container a{
    text-decoration: none;
}
.header-logo {
  display: flex;
  padding-top: 10px;
}
.header-logo h3 {
  padding: 20px;
  color: #492b1b;
}
.top-bar {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 1px;
}

.top-bar__links a {
  text-decoration: none;
}

.top-bar__links a:hover {
  text-decoration: none;
}

.top-bar .container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.top-bar__links {
  display: flex;
  gap: 1em;
}

.top-bar__links .header-link a {
  font-size: 0.9em;
}

.header-links {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;
}

.zoom-link {
  position: absolute;
  right: 5.8em;
  top: 2em;
}

.header-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2em;
  width: fit-content;
  position: relative;
}

.header-link a,
.dropdown-menu a {
  text-transform: uppercase;
  text-decoration: none;
  color: black;
  font-size: 1.2em;
}

.has-dropdown {
  position: relative;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 115%;
  left: -1.5em;
  background-color: white;
  width: fit-content;
  z-index: 100;
  padding: 0.5em 1em;
  gap: 1em;
  visibility: hidden;
  opacity: 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transform: translateY(20%);
  transition: all ease 500ms;
}

.has-dropdown:hover .dropdown-menu {
  visibility: visible;
  opacity: 1;
  transform: translateY(5%);
}

.dropdown-menu a:hover {
  text-decoration: underline;
  text-underline-offset: 0.1em;
}

.header-link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: black;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.header-link:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

.mobile-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

.mobile-header .container {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .header-links {
    width: 70%;
    gap: 1.5em;
  }
}

@media (max-width: 992px) {
  .main-header {
    display: none;
  }

  .mobile-header {
    display: flex;
  }

  .relative {
    position: relative !important;
    right: 0;
    top: 0;
  }
}

/* *=============================== */

.cart-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  /* Adjust transparency here */
  z-index: 1999;
  /* Make sure the overlay is below the sidebar */
  display: block;
}

.menu-bars {
  cursor: pointer;
}

.mobile-sidebar {
  height: 100vh;
  width: 30%;
  background-color: white;
  position: fixed;
  left: -110%;
  top: 0;
  transition: all ease-in-out 850ms;
  transition-delay: -500ms;
  z-index: 2000;
  padding: 1em;
}

.mobileSidebar--close-btn {
  cursor: pointer;
}

@media (max-width: 991px) {
  .mobile-sidebar {
    width: 40%;
  }
}

@media (max-width: 772px) {
  .mobile-sidebar {
    width: 45%;
  }
}

@media (max-width: 625px) {
  .mobile-sidebar {
    width: 50%;
  }
}

@media (max-width: 525px) {
  .mobile-sidebar {
    width: 60%;
  }
}

@media (max-width: 480px) {
  .mobile-sidebar {
    width: 90%;
  }
}

.mobile-sidebar.active {
  left: 0;
  background-color: white;
  border-radius: 0;
  padding-top: 1%;
}

.mobileSidebar {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.mobileSidebar--header {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobileSidebar--close-btn {
  background-color: transparent;
  border: none;
}

.mobile-sidebar--search {
  width: 100%;
}

.mobile-sidebar--search,
.mobile-sidebar--search input {
  width: 100%;
  padding: 0.5em;
}

.mobile-sidebar--search input::placeholder {
  color: var(--text-gray-color);
}

.mobileSidebar--menu_items {
  display: flex;
  flex-direction: column;
}

.mobileSidebar--menu_item {
  display: flex;
  align-items: center;
  padding: 0.5em 0;
  transition: ease 500ms;
  padding-left: 1em;
  border-bottom: 1px solid var(--border-color3);
}

.mobileSidebar--menu_item > * {
  width: 100%;
  padding: 1em 0;
  text-decoration: none;
  text-transform: uppercase;
  color: black;
}

.mobileSidebar--menu_item:hover {
  background-color: rgba(128, 128, 128, 0.174);
}

.mobileSidebar--menu_section {
  margin-top: 1em;
}

.mobileSidebar--top-bar {
  margin: 0.5em 0 0.5em 1em;
  display: flex;
  gap: 0.5em;
}

.mobileSidebar--top-bar a {
  text-decoration: none;
}

.mobileSidebar--top-bar a:hover {
  text-decoration: underline;
}

/* sticky header */
.sticky-header {
  position: fixed;
  top: 0;
  transform: translateY(-250%);
  left: 0;
  z-index: 1000;
  transition: all 500ms ease-in;
}

.sticky-header.active {
  transform: translateY(0);
  transition: all 400ms ease-in-out;
}
