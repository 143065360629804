.landing-section {
  width: 100%;
  height: 80vh;
  position: relative;
  padding-bottom: 5%;
}
.home-banner,
.home-banner img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.landing-section > * {
  position: relative;
  z-index: 3;
}
.home-banner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.home-banner::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.278),
    rgba(0, 0, 0, 0.48)
  );
  z-index: 2;
}
.landing-section__details {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 5%;
  gap: 1em;
}
.landing-section__details > * {
  color: white;
}
.landing-section__details h2 {
  font-size: 4em;
  line-height: 1;
}
.landing-section__details p:nth-of-type(2) {
  font-size: 1.5em;
}
.landing-buttons {
  display: flex;
  gap: 1em;
}
.credit-text {
  font-size: 18px;
  position: absolute;
  bottom: 4px;
  color: #ffffff;
  right: 15px;
  z-index: 1000;
}
@media (max-width: 767px) {
  .credit-text {
    left: 15px;
  }
}
