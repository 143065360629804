.upcoming-events {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    padding: 8em 0 4em 0;
    position: relative;
}

.upcoming-events-watermark {
    position: absolute;
    top: 1%;
}

.bottom-watermark {
    position: absolute;
    bottom: -3.5em;
}

.upcoming-events-watermark img,
.bottom-watermark img {
    height: 7em;
    width: 7em;
    object-fit: cover;
    opacity: 0.3;
}

.upcoming-events .container {
    display: flex;
    justify-content: space-between;
}

.events-container,
.updates-container {
    width: 49%;
}

.events-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: flex-start;
}

.events-container img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.events-container__inner-text {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 1em;
}

.updates-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-top: 3.15em;

}

.upcoming-events .container {
    margin-top: 4em;
}

.events-container ul {
    margin-left: 2em;
    text-align: left;
}

@media (max-width:1200px) {
    .upcoming-events {
        padding-top: 4em;
    }

    .upcoming-events .container {
        flex-direction: column;
    }

    .events-container,
    .updates-container {
        width: 100%;
    }

    .events-container__inner {
        display: flex;
        justify-content: space-between;
    }

    .events-container__inner-image,
    .events-container__inner-text {
        width: 49%;
    }

    .updates-container {
        flex-direction: row;
        justify-content: space-between;
    }

    .updates-container .video1,
    .updates-container .video2 {
        width: 49%;
        overflow: hidden;
    }
}

@media (max-width: 1080px) {
    .updates-container {
        flex-direction: column;
    }

    .updates-container .video1,
    .updates-container .video2 {
        width: 100%;
    }
}

@media (max-width: 900px) {
    .events-container__inner-image {
        width: 59%;
    }

    .events-container__inner-text {
        width: 39%;
    }
}

@media (max-width: 900px) {
    .events-container__inner {
        flex-direction: column;
        gap: 2em;
    }

    .events-container__inner-image,
    .events-container__inner-text {
        width: 100%;
    }
}